<template>
  <div>
    <v-card v-if="id" max-width="600">
      <v-form :disabled="isFormDisabled">
        <v-card-title>Account</v-card-title>
        <v-text-field label="ID" :value="id" disabled class="mx-5" />
        <v-text-field v-model="form.name" label="Name" class="mx-5" />
        <v-text-field v-model="form.url" label="URL" class="mx-5" />
        <v-text-field
          v-model="form.emailDomain"
          label="Domain Name"
          hint="Require new users to have an email address at the specified domain."
          class="mx-5"
        />
        <v-btn
          v-if="isUpdateButtonVisible"
          class="ma-5"
          :disabled="isUpdateButtonDisabled"
          @click="updateAccount"
        >
          Update account
        </v-btn>
      </v-form>
    </v-card>

    <v-snackbar v-model="accountUpdateSuccess" color="success" :timeout="3000">
      Updated!
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { isEqual } from 'lodash-es'

export default {
  data() {
    return {
      account: {},
      form: {},
      accountUpdateSuccess: false,
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['userCanUpdateAccounts']),
    ...mapState('account', ['id', 'name', 'url', 'emailDomain']),
    isFormDisabled() {
      return !this.user.isOwner
    },
    isUpdateButtonVisible() {
      return this.user.isOwner || this.userCanUpdateAccounts
    },
    isUpdateButtonDisabled() {
      return isEqual(this.account, this.form)
    },
  },
  async created() {
    await this.loadAccountInfo()

    this.account = {
      name: this.name,
      url: this.url,
      emailDomain: this.emailDomain,
    }
    this.form = { ...this.account }
  },
  methods: {
    ...mapActions('account', [
      'setAccountInfo',
      'updateAccountInfo',
      'loadAccountInfo',
    ]),
    async updateAccount() {
      this.isSubmitting = true
      try {
        await this.updateAccountInfo({
          id: this.id,
          payload: this.form,
        })
        this.accountUpdateSuccess = true
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>
